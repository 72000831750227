<template>
  <div class="panel" id="animation-panel">
    <div class="wrapper">
      <div class="liftLevel">
        <div class="liftItem" v-for="i in lift.list">
          <div v-if="monitorPageDisplay.floor.value == i" class="liftBody">
            <div class="frame" :class="{ isOpen: lift.isOpen }">
              <!-- <svg-icon
                v-if="lift.hasPeople"
                icon-class="man"
                class="liftMan"
              /> -->
              <!-- <div class="door leftDoor"></div>
              <div class="door rightDoor"></div> -->

              <div
                :class="
                  monitorPageDisplay.doorStatus.value == 2 ||
                  monitorPageDisplay.doorStatus.value == 0 ||
                  monitorPageDisplay.doorStatus.value == 3
                    ? 'door dooronline'
                    : 'door leftDoor'
                "
              ></div>
              <div
                :class="
                  monitorPageDisplay.doorStatus.value == 2 ||
                  monitorPageDisplay.doorStatus.value == 0 ||
                  monitorPageDisplay.doorStatus.value == 3
                    ? 'door dooronline'
                    : 'door rightDoor'
                "
              ></div>
              <i
                v-if="monitorPageDisplay.direction.value == 1"
                class="el-icon-caret-top"
              ></i>
              <i
                v-else-if="monitorPageDisplay.direction.value == 2"
                class="el-icon-caret-bottom"
              ></i>
              <i v-else class="el-icon-remove"></i>
            </div>
          </div>
          <div :class="i > iMinFloor && i < iMaxFloor ? 'floor' : 'floor online'">{{ i }}</div>
        </div>
      </div>
      <div class="dt-no">{{ elevator.v_elevator_name }}</div>
      <!-- <i class="el-icon-caret-top"></i>
     <i class="el-icon-remove"></i>
     <i class="el-icon-caret-bottom"></i> -->
      <!-- <img class="elevator-img" id="elevator-img" v-if="monitorPageDisplay.doorStatus.value == 2" src="@/assets/ic_elevator_open.png">
            <img class="elevator-img" id="elevator-img" v-else-if="monitorPageDisplay.doorStatus.value == 0" src="@/assets/ic_elevator_open.png">
            <img class="elevator-img" id="elevator-img" v-else-if="monitorPageDisplay.doorStatus.value == 3" src="@/assets/ic_elevator_open.png">
            <img class="elevator-img" id="elevator-img" v-else src="@/assets/elevator.png">
            <div class="dt-floor">
                <img style="width:50%; height:auto;" v-if="monitorPageDisplay.direction.value == 1" src="@/assets/ic_up.png"> 上
                <img style="width:50%; height:auto;" v-else-if="monitorPageDisplay.direction.value == 2" src="@/assets/ic_down.png"> 下
                <img style="width:50%; height:auto;" v-else src="@/assets/ic_floor.png"> 平
                <div>{{monitorPageDisplay.floor.value}}</div>
            </div> -->
      <!-- <div class="dt-no">{{ elevator.v_elevator_name }}</div> -->
    </div>
  </div>
</template>
<script>
import { getElevatorInfo } from '@/api/monitor/index'
import Monitor from '@/pages/monitor/monitor'
export default {
  data() {
    return {
      lift: {
        currentIndex: 1,
        direction: 0,
        hasPeople: false,
        isOpen: false,
        list: [],
        iMinFloor: -2,
        iMaxFloor: 6,
      },
    }
  },
  props: ['elevator'],
  mixins: [Monitor],
  mounted() {
    //监听元素变化

    this.initSocket(this.elevator.v_elevator_code, [
      'CarRoof',
      'MotorRoom',
      'SINGLEBOX',
    ])
    this.initLift()
  },
  created() {
    getElevatorInfo(this.elevator.v_elevator_id).then((res) => {
      this.iMinFloor = res.info.iMinFloor - 1
      this.iMaxFloor = res.info.iMaxFloor + 1
    })
  },
  methods: {
    getElevatorCode() {
      return this.elevator.v_elevator_code
    },
    initLift() {
      let min = -2
      let max = 28
      let list = []
      for (let i = min; i <= max; i++) {
        if (i !== 0) {
          list.push(i)
        }
      }
      this.lift.list = list
      //   console.log(this.lift, 'this.lift')
    },
  },
  watch: {
    // elevatorCode(newVal,oldVal) {
    //     debugger
    //     console.log(newVal, oldVal)
    // }
  },
}
</script>
<style lang="less" scoped>
.liftLevel {
  // background-color: pink;
  padding-left: 20px;
  width: 280px;
  height: 500px;
  flex: none;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  .liftItem {
    background-color: #cdcecd;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 70px;
    height: 50px;
    border: 1px solid #e2e5e3;
    position: relative;
    .floor {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: rgb(0, 123, 229);
    }
    .liftBody {
      position: relative;
      width: 100%;
      height: 50px;
      margin-right: 24px;
      padding: 2px;
      .frame {
        width: 100%;
        height: 100%;
        border: 2px solid #333;
        position: relative;
        overflow: hidden;
        border-radius: 2px;
      }
      .liftMan {
        font-size: 28px;
        position: absolute;
        left: -4px;
        bottom: 5px;
      }
      .door {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        background-color: rgba(#4bbbf6, 0.3);
        transition: all 0.5s;
      }
      .dooronline {
        background-color: #ccc;
      }
      .door.leftDoor {
        left: 0;
        border-right: 1px solid rgba(#000, 0.3);
      }
      .door.rightDoor {
        right: 0;
        border-left: 1px solid rgba(#000, 0.3);
      }
      .frame.isOpen {
        .door.leftDoor {
          left: -10px;
        }
        .door.rightDoor {
          right: -10px;
        }
      }
      .el-icon-caret-top,
      .el-icon-caret-bottom,
      .el-icon-remove {
        position: absolute;
        right: 0px;
        color: green;
      }
      .el-icon-remove {
        right: 1px;
        top: 50%;
        transform: translateY(-50%);
        color: #333;
        font-size: 14px;
        opacity: 0.5;
        display: none;
      }
      .el-icon-caret-top {
        top: 1px;
      }
      .el-icon-caret-bottom {
        bottom: 2px;
      }
    }
  }
}
.online {
  background-color: #ccc !important;
}

.panel {
  display: flex;
  justify-content: center;
}

.wrapper {
  //   position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   background-color: red;

  .dt-no {
    // position: absolute;
    // bottom: 0;
    width: 300px;
    font-size: 16px;
    height: 50px;
    color: #fff;
    display: flex;
    background-color: #707070;
    justify-content: center;
    align-items: center;
  }

  .dt-floor {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    width: 29%;
    color: #fff;
    height: 11%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;

    div {
      font-size: 15px;
      //   color: red;
    }
  }
}

.elevator-img {
  height: 100%;
  width: 100%;
}
</style>
