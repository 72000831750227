<template>
  <div style="height: 100%">
    <SideLayout ref="sideRef" :minRightWidth="180" :minLeftWidth="minLeftWidth">
      <template v-slot:left-content>
        <ElevatorSelector
          @onExpand="onLayoutExpand"
          @changeElevatorItems="updateCheckedElevators"
          @didSelectVillage="onSelectVillage"
        />
      </template>
      <template v-slot:right-content>
        <div id="right-wrapper" ref="adaptionBox" class="right-content-wrapper">
          <div class="animation-top">
            {{ checkedVillage ? checkedVillage.v_village_name : '--' }}
          </div>
          <div class="animation-bottom-wrapper">
            <div
              id="animationBottom"
              ref="animationBottom"
              class="animation-bottom"
              :style="{ 'min-width': `${minCollectionWidth}px` }"
            >
              <template v-if="animation_data.length == 1">
                <div
                  class="animation-item-1"
                  v-for="(item, index) in animation_data"
                  :key="index"
                >
                  <ElevatorAnimation :elevator="item" />
                </div>
              </template>
              <template v-else>
                <div
                  class="animation-item"
                  :style="{
                    width: `${itemWidth}px`,
                    'margin-left': `${marginH}px`,
                    'margin-right': `${marginH}px`,
                  }"
                  :class="expand ? 'animation-item-4' : 'animation-item-5'"
                  v-for="(item, index) in animation_data"
                  :key="index"
                >
                  <ElevatorAnimation :elevator="item" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </SideLayout>
  </div>
</template>
<script>
//:style="{'min-width':`${minCollectionWidth}px`}"
const refItemWidth = 280
const refItemHeight = 389


import SideLayout from '@/components/sideLayout'
import ElevatorAnimation from '../components/elevatorAnimation'
import ElevatorSelector from '../components/elevatorSelector'
export default {
  data() {
    return {
      checkedVillage: null,
      expand: false,
      animation_data: [],
      radtio: 1,
      minLeftWidth: 261,
      minCollectionWidth: 0,
      itemWidth: refItemWidth,
      itemHeight: refItemHeight,
      marginH: 5,
    }
  },
  components: {
    SideLayout,
    ElevatorAnimation,
    ElevatorSelector,
  },
  mounted() {
    this.expand = true
    let elementResizeDetectorMaker = require('element-resize-detector')
    //监听元素变化
    let erd = elementResizeDetectorMaker()
    let that = this
    erd.listenTo(
      document.getElementById('animationBottom'),
      function (element) {
        that.resizeOnWindowChange()
      }
    )
  },

  methods: {
    resizeOnWindowChange() {
      let curWidth = document.documentElement.clientWidth
      let zoomRatio = curWidth / 1920

      zoomRatio = zoomRatio > 1 ? 1 : zoomRatio
      let width = zoomRatio * refItemWidth
      let containerWidth = this.$refs.animationBottom.offsetWidth

      this.marginH = this.expand
        ? Math.floor((containerWidth - 4 * width - 20) / 8)
        : Math.floor((containerWidth - 5 * width - 20) / 10)
      this.marginH = this.marginH < 5 ? 5 : this.marginH

      if (this.marginH > 5 || width > this.itemWidth) {
        this.itemWidth = width
        this.itemHeight = zoomRatio * refItemHeight
        this.minCollectionWidth = 0
      } else {
        let minWidth = 0
        if (this.expand) {
          minWidth = 4 * this.itemWidth + 8 * 5 + 20
        } else {
          minWidth = 5 * this.itemWidth + 10 * 5 + 20
        }
        this.minCollectionWidth = minWidth
      }
    },

    updateCheckedElevators(elevators) {
        
      this.animation_data = []
      this.$nextTick(() => {
        this.animation_data = elevators
      })
    },
    onLayoutExpand(expand) {
      this.expand = expand
      this.minLeftWidth = expand ? 261 : 16
      this.resizeOnWindowChange()
    },
    onSelectVillage(village) {
        console.log(444);
      this.checkedVillage = village
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';

.left-wrapper {
  min-width: 300px;
}

.right-content-wrapper {
  position: relative;
  margin: 10px;
  height: calc(100% - 20px);
  @include background_color(multipleMonitorRightContainerBgColor);
  @include font_color(rightContainerTitleTextColor);
}

.animation-top {
  height: 60px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  font-size: 18px;
  @include background_color(multipleMonitorRightContainerBgColor);
  @include font_color(rightContainerTitleTextColor);
}

.animation-bottom-wrapper {
  height: calc(100% - 60px);
  overflow-y: auto;
}

.animation-bottom {
  position: relative;
  display: flex;
  padding: 10px;
  align-content: flex-start;
  flex-wrap: wrap;
}

.animation-item {
  height: calc((100% - 10px) / 2);
  margin: 20px 5px;
}

.animation-item-4 {
  width: calc((100% - 30px) / 4);
}

.animation-item-5 {
  width: calc((100% - 40px) / 5);
}
.animation-item-1 {
  margin: 40px auto;
  //   background-color: pink;
}
</style>